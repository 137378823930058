import '@hotwired/turbo-rails';
import 'channels';
import 'controllers';

import * as ActiveStorage from '@rails/activestorage';
import Rails from '@rails/ujs';
import Chart from 'chart.js/auto';
import Turbolinks from 'turbolinks';
import 'flowbite';

Rails.start();
ActiveStorage.start();
Turbolinks.start();

import './stylesheets/application.css';

// Turbo.session.drive = false;

const loadAdminCharts = () => {
  if (document.getElementById('chart1')) {
    var ctx = document.getElementById('chart1').getContext('2d');
  }
  if (document.getElementById('chart2')) {
    var ctx2 = document.getElementById('chart2').getContext('2d');
  }
  if (document.getElementById('chart3')) {
    var ctx3 = document.getElementById('chart3').getContext('2d');
  }
  if (document.getElementById('chart4')) {
    var ctx4 = document.getElementById('chart4').getContext('2d');
  }

  if (ctx) {
    var myChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: JSON.parse(ctx.canvas.dataset.labels),
        datasets: [
          {
            label: 'Number of units',
            data: JSON.parse(ctx.canvas.dataset.data),
            backgroundColor: [
              'rgba(67, 56, 202, 1)',
              'rgba(67, 56, 202, 1)',
              'rgba(67, 56, 202, 1)',
              'rgba(67, 56, 202, 1)',
              'rgba(67, 56, 202, 1)',
              'rgba(67, 56, 202, 1)',
            ],
            borderColor: [
              'rgba(67, 56, 202, 1)',
              'rgba(67, 56, 202, 1)',
              'rgba(67, 56, 202, 1)',
              'rgba(67, 56, 202, 1)',
              'rgba(67, 56, 202, 1)',
              'rgba(67, 56, 202, 1)',
            ],
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
  }

  if (ctx2) {
    var myChart2 = new Chart(ctx2, {
      type: 'bar',
      data: {
        labels: JSON.parse(ctx2.canvas.dataset.labels),
        datasets: [
          {
            label: 'Number of orders',
            data: JSON.parse(ctx2.canvas.dataset.data),
            backgroundColor: [
              'rgba(67, 56, 202, 1)',
              'rgba(67, 56, 202, 1)',
              'rgba(67, 56, 202, 1)',
              'rgba(67, 56, 202, 1)',
              'rgba(67, 56, 202, 1)',
              'rgba(67, 56, 202, 1)',
            ],
            borderColor: [
              'rgba(67, 56, 202, 1)',
              'rgba(67, 56, 202, 1)',
              'rgba(67, 56, 202, 1)',
              'rgba(67, 56, 202, 1)',
              'rgba(67, 56, 202, 1)',
              'rgba(67, 56, 202, 1)',
            ],
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
  }

  if (ctx3) {
    var myChart3 = new Chart(ctx3, {
      type: 'doughnut',
      data: {
        labels: JSON.parse(ctx3.canvas.dataset.labels),
        datasets: [
          {
            label: 'Number of sales',
            data: JSON.parse(ctx3.canvas.dataset.data),
            backgroundColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
  }

  if (ctx4) {
    var myChart4 = new Chart(ctx4, {
      type: 'doughnut',
      data: {
        labels: JSON.parse(ctx4.canvas.dataset.labels),
        datasets: [
          {
            label: 'Number of sales',
            data: JSON.parse(ctx4.canvas.dataset.data),
            backgroundColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
  }
};

const userMenuButtonClick = () => {
  const userMenuButton = document.querySelector('#user-menu-button');
  if (userMenuButton !== null) {
    userMenuButton.addEventListener('click', userMenuButtonClickHandler);
  }
};

const userMenuButtonClickHandler = () => {
  const userMenu = document.querySelector('#user-menu');

  if (userMenu.classList.contains('opacity-0')) {
    console.log('entered open state');
    userMenu.classList.remove('opacity-0');
    userMenu.classList.add('opacity-100');
  } else {
    console.log('entered close state');
    userMenu.classList.remove('opacity-100');
    userMenu.classList.add('opacity-0');
  }
};

document.addEventListener('turbo:load', () => {
  loadAdminCharts();
  userMenuButtonClick();
});

document.addEventListener('turbo:load', () => {
  let cardElement = document.querySelector('#card-element');

  if (cardElement !== null) {
    // console.log('cardElement !== null');
    setupStripe();
  }

  let newCard = document.querySelector('#use-new-card');
  if (newCard !== null) {
    newCard.addEventListener('click', (event) => {
      event.preventDefault();
      document.querySelector('#payment-form').classList.remove('hidden');
      document.querySelector('#existing-card').classList.add('hidden');
    });
  }
});

function setupStripe() {
  const stripe_key = document
    .querySelector("meta[name='stripe-key']")
    .getAttribute('content');
  const stripe = Stripe(stripe_key);

  const elements = stripe.elements();
  const card = elements.create('card');
  card.mount('#card-element');

  var displayError = document.getElementById('card-errors');

  card.addEventListener('change', (event) => {
    if (event.error) {
      displayError.textContent = event.error.message;
    } else {
      displayError.textContent = '';
    }
  });

  const existingCardForm = document.querySelector('#existing-card');
  const form = document.querySelector('#payment-form');
  let paymentIntentId = form.dataset.paymentIntent;
  let setupIntentId = form.dataset.setupIntent;
  let paymentMethodId;
  if (existingCardForm) {
    console.log('existingCardForm');
    paymentMethodId = document.getElementsByName('payment_method_id')[0].value;

    existingCardForm.addEventListener('submit', (event) => {
      console.log('existing card form clicked');
      event.preventDefault();

      let data = {
        payment_method: paymentMethodId,
      };

      stripe.confirmCardPayment(paymentIntentId, data).then((result) => {
        if (result.error) {
          var errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
        } else {
          form.submit();
        }
      });
    });
  }

  // console.log(form);
  console.log(paymentMethodId);

  if (paymentIntentId) {
    if (form.dataset.status == 'requires_action') {
      console.log('requires action');
      stripe
        .confirmCardPayment(paymentIntentId, {
          setup_future_usage: 'off_session',
        })
        .then((result) => {
          if (result.error) {
            displayError.textContent = result.error.message;
            form.querySelector('#card-details').classList.remove('hidden');
          } else {
            form.submit();
          }
        });
    }
  }

  form.addEventListener('submit', (event) => {
    console.log('form submit clicked');
    event.preventDefault();

    let name = form.querySelector('#name_on_card').value;
    let data = {
      payment_method_data: {
        card: card,
        billing_details: {
          name: name,
        },
      },
    };

    // Complete a payment intent - fill out cc info
    if (paymentIntentId) {
      console.log('complete a payment intent');
      stripe
        .confirmCardPayment(paymentIntentId, {
          payment_method: data.payment_method_data,
          setup_future_usage: 'off_session',
          save_payment_method: true,
        })
        .then((result) => {
          if (result.error) {
            displayError.textContent = result.error.message;
            form.querySelector('#card-details').classList.remove('hidden');
          } else {
            form.submit();
          }
        });
    } else if (setupIntentId) {
      console.log('update a card or subscribe with a trial');
      // Updating a card or subscribing with a trial (using a SetupIntent)
      stripe
        .confirmCardSetup(setupIntentId, {
          payment_method: data.payment_method_data,
        })
        .then((result) => {
          if (result.error) {
            displayError.textContent = result.error.message;
          } else {
            addHiddenField(
              form,
              'payment_method_id',
              result.setupIntent.payment_method
            );
            form.submit();
          }
        });
    }
    // Subscribing with no trial
    else {
      console.log('subscribe with no trial');
      data.payment_method_data.type = 'card';
      stripe.createPaymentMethod(data.payment_method_data).then((result) => {
        if (result.error) {
          displayError.textContent = result.error.message;
        } else {
          addHiddenField(form, 'payment_method_id', result.paymentMethod.id);
          form.submit();
        }
      });
    }
  });
}

function addHiddenField(form, name, value) {
  let hiddenInput = document.createElement('input');
  hiddenInput.setAttribute('type', 'hidden');
  hiddenInput.setAttribute('name', name);
  hiddenInput.setAttribute('value', value);
  form.appendChild(hiddenInput);
}
